import React, {useEffect, useState} from 'react';
import back2 from './img_h5@2x.png';
import logo from './sys_login_logotext_bottom@2x.png';
import './App.css';

function App() {

    const [params, setParams] = useState(null)

    useEffect(() => {
        let url = document.location.toString();
        let arrObj = url.split('?');
        let param = Object.create(null);
        if (arrObj.length > 1) {
            arrObj = decodeURI(arrObj[1]).split('&');
            arrObj.forEach(item => {
                item = item.split('=');
                param[item[0]] = item[1];
            });
        }
        setParams(param)
        openinstall()
    }, [])


    const openinstall = () => {
        var data = window.OpenInstall.parseUrlParams();
        new window.OpenInstall({
            appKey: "ct0opx",
            onready: function () {
                this.schemeWakeup({data: data});
                var m = this
                const button = document.getElementById("download");
                const button2 = document.getElementById("download2");
                button.onclick = function () {
                    m.wakeupOrInstall({data: data});
                    return false;
                }
                button2.onclick = function () {
                    m.wakeupOrInstall({data: data});
                    return false;
                }
            }
        }, data);
    }


    return (
        <div className="slian">
            <header className="slian-header">
                <img src={logo} className="header-left" alt="logo"></img>
                <span id={"download"} className="header-right">
          立即下载
        </span>
            </header>
            <main>
                <div className="main-header">
                    <div className="main-img">
                        <img src={back2} className="main-backImg" alt="back2"></img>
                        <img src={decodeURIComponent(params?.avatar)} className="main-avater" alt="avater"></img>
                    </div>
                    <span className="main-title">{params?.name}</span>
                    {params?.type === '1' ? (
                        <div>
                            <span className="main-text-nav">{params?.typeName}</span>
                            <span className="main-text">房间ID: {params?.roomID}</span>
                        </div>
                    ) : (
                        <span className="main-text">好友ID: {params?.uid}</span>
                    )}
                </div>
                <div className="main-footer">
          <span className="main-button" id={"download2"}>
            {params?.type === '1' ? '进入房间互动' : '快来和我玩吧'}
          </span>
                </div>
            </main>
        </div>
    );
}

export default App;
